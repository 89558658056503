import { Grid, Paper, Typography } from '@mui/material'

import whatsappImage from '../../assets/images/help/whatsapp.svg'
import { HelpTypes } from './Helps/HelpTypes'

export const helpTypes = {
  WHATSAPP: {
    title: 'WhatsApp',
    image: {
      source: whatsappImage,
      alt: 'Imagem de um balão de fala com telefone no centro'
    }
  }
}

export type HelpType = keyof typeof helpTypes

export const helpContacts = {
  MAIL: {
    label: 'contato@4ies.com.br',
    value: 'mailto:contato@4ies.com.br'
  },
  PHONE: {
    label: '(19) 3201-9516',
    value: 'tel://1932019516'
  },
  WHATSAPP: {
    label: '(11) 91552-3600',
    value: 'https://wa.me/5511915523600'
  }
}

interface HelpBoxProps {
  message?: string
  description?: string
  size?: {
    xs?: number
    sm?: number
    md?: number
    lg?: number
    xl?: number
  }
}
const HelpBox = ({ message, size, description }: HelpBoxProps) => {
  return (
    <Grid container spacing={2} sx={{ flexGrow: 1 }} justifyContent="center">
      <Grid item xs={12} sm={12}>
        <Paper
          sx={{
            p: 2
            // backgroundColor: 'rgba(255, 61, 113, 0.25)'
          }}
          variant="outlined"
          elevation={0}
        >
          <Typography>{message}</Typography>
          <Typography>
            {description || 'Entre em contato por uma das opções abaixo:'}
          </Typography>
        </Paper>
      </Grid>
      {Object.entries(helpTypes).map(([key, item]) => {
        return (
          <Grid item key={key} xs={6} sm={4} {...size}>
            <HelpTypes
              title={item.title}
              image={item.image}
              contact={helpContacts[key as HelpType]}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export { HelpBox }
